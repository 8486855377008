<template>
    <w-icon v-bind="iconBindings"/>
</template>

<script>
export default {
    name: 'QuestionsIcon',
    props: {
        item: {
            type: Object,
            default: null
        }
    },
    computed: {
        iconBindings() {
            return this.item.type === 'folder'
                ? { value: 'FOLDER', color: 'warning' }
                : { value: 'QUESTIONS' }
        }
    }
}
</script>

<style lang=scss>

</style>
