<template>
    <div
        v-if="item"
        class="d-flex align-start text-truncate"
    >
        <questions-icon
            :item="item"
            class="mr-2"
        />
        <div class="text-truncate">
            <div class="text--primary caption font-weight-bold text-truncate">{{ item.title }}</div>
            <questions-list-shared-period
                :item="item"
                :shared-type="sharedType"
                :shared-uuid="sharedUuid"
                inline
                show-begin
                class="mb-1"
            />
            <div
                v-if="item.type === 'questions' && showSummary"
                class="caption"
            >
                <span
                    v-if="item.options.duration"
                >
                    {{ $trans('Duration') }}:
                    <span class="font-weight-semibold">
                        {{ item.options.duration | questDurationLabel }}
                    </span>
                    <br>
                </span>
                <span v-if="item.sets_count">
                    {{ $trans('Questions count') }}:
                    <span class="font-weight-semibold">{{ item.sets_count }}</span>
                    <br>
                </span>
                <span v-if="item.sets_sum_score">
                    {{ $trans('Estimate') }}:
                    <span class="font-weight-semibold">{{ item.sets_sum_score | questScoresLabel }}</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import QuestionsIcon from '@apps/questions/components/QuestionsIcon'
import questionsFiltersMixin from '@apps/questions/mixins/questionsFiltersMixin'
import QuestionsListSharedPeriod from '@apps/questions/components/QuestionsList/QuestionsListSharedPeriod'

export default {
    name: 'QuestionsTitle',
    mixins: [questionsFiltersMixin],
    components: { QuestionsListSharedPeriod, QuestionsIcon },
    props: {
        item: {
            type: Object,
            default: null
        },
        showSummary: {
            type: Boolean,
            default: false
        },
        sharedType: String,
        sharedUuid: String,
    }
}
</script>

<style lang=scss>

</style>
